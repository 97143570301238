export default {
  meta_title: 'Install — PennyLane',
  meta_description:
    'See how to install PennyLane and its plugins. Install the pip package, build from the latest GitHub source code, and get the one-line command for installing all plugins.',
  meta_image:
    'https://assets.cloud.pennylane.ai/pennylane_website/pages/shared/open_graph.png',
  hero: {
    title: 'Install PennyLane',
    sub_title: `The only requirement for installing PennyLane is Python.

  PennyLane is compatible with TensorFlow, PyTorch, and JAX.

  Binaries provided for MacOS, Windows, Linux, x86, PowerPC, and ARM.`,
    tabs: {
      sections: [{ labels: ['Stable', 'Preview', 'GitHub'] }],
      combinations: [
        {
          combination: ['Stable'],
          code_comment: `
        # install the latest released
        # version of PennyLane`,
          code: `pip install pennylane --upgrade`,
        },
        {
          combination: ['Preview'],
          code_comment: `# install the latest development version of PennyLane`,
          code: `pip install git+https://github.com/PennyLaneAI/pennylane.git#egg=pennylane`,
        },
        {
          combination: ['GitHub'],
          code_comment: `# download and install the latest source code from GitHub`,
          code: `git clone https://github.com/PennyLaneAI/pennylane.git
          cd pennylane
          pip install -e .`,
          note: '# Note: if installing from source for development, changes to plugin device registration will require pip install -e. to be re-run to take effect.',
        },
      ],
    },
    corner_images: {
      bottom_left_image: `https://assets.cloud.pennylane.ai/pennylane_website/pages/install/hero_1.png`,
      top_right_image: `https://assets.cloud.pennylane.ai/pennylane_website/pages/install/hero_2.png`,
    },
    bottom_link: {
      text: `Watch install video`,
      url: `https://youtube.com/watch?v=RDdYapLntbE`,
    },
  },
  pennylane_cloud_section: {
    title: `Using PennyLane on the Cloud`,
    subtitle: `PennyLane is available pre-installed on the following cloud services.`,
    partners: [
      {
        logo: `https://assets.cloud.pennylane.ai/partners/qbraid.png`,
        logoAltText: `qBraid`,
        link: `https://www.qbraid.com/`,
      },
      {
        logo: `https://assets.cloud.pennylane.ai/partners/aws.svg`,
        logoAltText: `Amazon Braket`,
        link: `https://aws.amazon.com/braket/`,
      },
      {
        logo: `https://assets.cloud.pennylane.ai/partners/covalent.png`,
        logoAltText: `Covalent`,
        link: `https://www.covalent.xyz/`,
      },
    ],
    description: `PennyLane can also be installed on Google Colab by running <code>!pip install pennylane</code>, and restarting the runtime.`,
  },
  high_performance_section: {
    cta_hero: {
      title: 'High Performance Computing and GPUs',
      image:
        'https://assets.cloud.pennylane.ai/pennylane_website/pages/install/lightning.png',
      description: 'PennyLane comes with built-in high performance simulators.',
      cta_links: [
        {
          link_text: 'High performance statevector simulator',
          link: 'https://docs.pennylane.ai/projects/lightning/en/stable/lightning_qubit/device.html',
        },
        {
          link_text: 'CUDA multi-GPU statevector simulator',
          link: 'https://docs.pennylane.ai/projects/lightning/en/stable/lightning_gpu/device.html',
        },
      ],
      image_right_aligned: true,
    },
    tabs: {
      sections: [
        { sectionName: 'OS', labels: ['Linux', 'Mac', 'Windows'] },
        { sectionName: 'Device', labels: ['CPU', 'NVIDIA CUDA', 'NVIDIA CUDA - Tensor Networks', 'AMD ROCm'] },
        {
          sectionName: 'Type',
          labels: ['pip', 'Conda', 'Spack', 'Docker', 'Source'],
        },
      ],
      combinations: [
        {
          combination: ['Linux', 'CPU', 'pip'],
          code_comment: ``,
          code: `pip install pennylane`,
        },
        {
          combination: ['Linux', 'CPU', 'Conda'],
          code_comment: ``,
          code: `conda install -c conda-forge pennylane`,
        },
        {
          combination: ['Linux', 'CPU', 'Spack'],
          code_comment: ``,
          code: `spack install py-pennylane`,
        },
        {
          combination: ['Linux', 'CPU', 'Docker'],
          code_comment: ``,
          code: `docker pull pennylaneai/pennylane:latest-lightning-qubit`,
        },
        {
          combination: ['Linux', 'CPU', 'Source'],
          code_comment: `# Visit the following URL for instructions on installing Lightning from source:
          # https://github.com/PennyLaneAI/pennylane-lightning#lightning-qubit-installation`,
          code: ``,
        },
        {
          combination: ['Linux', 'NVIDIA CUDA', 'pip'],
          code_comment: `# To install Lightning-GPU with NVIDIA CUDA support, the following packages need to be installed`,
          code: `
          pip install custatevec_cu12
          pip install pennylane-lightning-gpu`,
        },
        {
          combination: ['Linux', 'NVIDIA CUDA - Tensor Networks', 'pip'],
          code_comment: `# To install Lightning-Tensor with NVIDIA CUDA support, the following packages need to be installed`,
          code: `
          pip install cutensornet-cu12
          pip install pennylane-lightning-tensor`,
        },
        {
          combination: ['Linux', 'NVIDIA CUDA', 'Conda'],
          code_comment: ``,
          code: `conda install -c conda-forge pennylane-lightning-gpu`,
        },
        {
          combination: ['Linux', 'NVIDIA CUDA', 'Spack'],
          code_comment: `# Run the following command after replacing <arch> with the label for your architecture found on this page:
          # https://github.com/spack/spack/blob/v0.21.1/var/spack/repos/builtin/packages/kokkos/package.py#L121`,
          code: `spack install py-pennylane-lightning-kokkos +cuda cuda_arch=<arch>`,
        },
        {
          combination: ['Linux', 'NVIDIA CUDA', 'Docker'],
          code_comment: ``,
          code: `docker pull pennylaneai/pennylane:latest-lightning-gpu`,
        },
        {
          combination: ['Linux', 'NVIDIA CUDA', 'Source'],
          code_comment: `# Visit the following URL for instructions on installing Lightning-GPU from source:
          # https://github.com/PennyLaneAI/pennylane-lightning#lightning-gpu-installation`,
          code: ``,
        },
        {
          combination: ['Linux', 'NVIDIA CUDA - Tensor Networks', 'Source'],
          code_comment: `# Visit the following URL for instructions on installing Lightning-Tensor from source:
          # https://github.com/PennyLaneAI/pennylane-lightning#lightning-tensor-installation`,
          code: ``,
        },
        {
          combination: ['Linux', 'AMD ROCm', 'Spack'],
          code_comment: `# Run the following command after replacing <arch> with the label for your architecture found on this page:
          # https://github.com/spack/spack/blob/v0.21.1/var/spack/repos/builtin/packages/kokkos/package.py#L153`,
          code: `spack install py-pennylane-lightning-kokkos +rocm amdgpu_target=<arch>`,
        },
        {
          combination: ['Linux', 'AMD ROCm', 'Docker'],
          code_comment: ``,
          code: `docker pull pennylaneai/pennylane:latest-lightning-kokkos-rocm`,
        },
        {
          combination: ['Linux', 'AMD ROCm', 'Source'],
          code_comment: `# Visit the following URL for instructions on installing Lightning from source:
          # https://github.com/PennyLaneAI/pennylane-lightning#lightning-kokkos-installation`,
          code: ``,
        },
        {
          combination: ['Mac', 'CPU', 'pip'],
          code_comment: ``,
          code: `pip install pennylane`,
        },
        {
          combination: ['Mac', 'CPU', 'Conda'],
          code_comment: ``,
          code: `conda install -c conda-forge pennylane`,
        },
        {
          combination: ['Mac', 'CPU', 'Spack'],
          code_comment: ``,
          code: `spack install py-pennylane`,
        },
        {
          combination: ['Mac', 'CPU', 'Source'],
          code_comment: `# Visit the following URL for instructions on installing Lightning from source:
          # https://github.com/PennyLaneAI/pennylane-lightning#lightning-qubit-installation`,
          code: ``,
        },
        {
          combination: ['Windows', 'CPU', 'pip'],
          code_comment: ``,
          code: `pip install pennylane`,
        },
        {
          combination: ['Windows', 'CPU', 'Conda'],
          code_comment: ``,
          code: `conda install -c conda-forge pennylane`,
        },
        {
          combination: ['Windows', 'CPU', 'Source'],
          code_comment: `# Visit the following URL for instructions on installing Lightning from source:
          # https://github.com/PennyLaneAI/pennylane-lightning#lightning-qubit-installation`,
          code: ``,
        },
        {
          combination: ['Windows', 'NVIDIA CUDA', 'Source'],
          code_comment: `# Visit the following URL for instructions on installing Lightning-GPU from source:
          # https://github.com/PennyLaneAI/pennylane-lightning#lightning-gpu-installation`,
          code: ``,
        },
        {
          combination: ['Windows', 'NVIDIA CUDA - Tensor Networks', 'Source'],
          code_comment: `# Visit the following URL for instructions on installing Lightning-Tensor from source:
          # https://github.com/PennyLaneAI/pennylane-lightning#lightning-tensor-installation`,
          code: ``,
        },
        {
          combination: ['Windows', 'AMD ROCm', 'Source'],
          code_comment: `# Visit the following URL for instructions on installing Lightning from source:
          # https://github.com/PennyLaneAI/pennylane-lightning#lightning-kokkos-installation`,
          code: ``,
        },
      ],
    },
    bottom_link: {
      text: ``,
      url: ``,
    },
  },
  devices_and_quantum_hardware_section: {
    title: `Devices and Quantum hardware`,
    description: `PennyLane supports a wide range of quantum simulators and hardware devices.`,
    partners: [
      {
        link: 'https://amazon-braket-pennylane-plugin-python.readthedocs.io/en/latest/',
        image: 'https://assets.cloud.pennylane.ai/partners/aws.svg',
        aria_label: `Amazon Braket`,
      },
      {
        link: 'https://docs.pennylane.ai/projects/qiskit/en/latest/',
        image: 'https://assets.cloud.pennylane.ai/partners/ibm.png',
        fullWidthImage: true,
        aria_label: `IBM Quantum`,
      },
      {
        link: 'https://docs.pennylane.ai/projects/cirq/en/latest/',
        image: 'https://assets.cloud.pennylane.ai/partners/cirq.png',
        aria_label: `Google Quantum`,
      },
      {
        link: 'https://docs.pennylane.ai/projects/ionq/en/latest/',
        image: 'https://assets.cloud.pennylane.ai/partners/ionq.png',
        aria_label: `IonQ`,
      },
      {
        link: 'https://docs.pennylane.ai/projects/aqt/en/latest/',
        image: 'https://assets.cloud.pennylane.ai/partners/aqt.png',
        aria_label: `AQT`,
      },
    ],
    all_plugins_link: { text: 'Browse all devices', url: '/devices' },
  },
  get_started_with_pennylane: {
    background_image:
      'https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/confetti_blue.svg',
    background_colour: '#0074e0',
    title: `Get started with PennyLane`,
    description: `Get started on your journey or share your expertise with the community.`,
    cards: [
      {
        title: 'Forum',
        description: 'Have a technical question? Join our discussion forum.',
        cta: {
          label: 'Ask a question',
          link: 'https://discuss.pennylane.ai/',
        },
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/icons/forum_illustration.png',
      },
      {
        title: 'Slack',
        description: 'Engage with a global quantum community.',
        cta: {
          label: 'Connect now',
          link: 'https://join.slack.com/t/xanadu-quantum/shared_invite/zt-1i8v8v49d-S76QxXm3OKCm9g0bvWvDpg',
        },
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/icons/slack_illustration.png',
      },
      {
        title: 'Discord',
        description: 'Meet and chat with other PennyLane users.',
        cta: {
          label: 'Join the conversation',
          link: 'https://discord.com/invite/gnySM3nrN3',
        },
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/icons/discord_illustration.png',
      },
    ],
  },
}
